/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import axios, {AxiosResponse} from 'axios'
import path from 'path'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {

  const intl = useIntl()
  const {pathname} = useLocation()
  const [isDropDown, setIsDropDown] = useState(false)
  const [courseOpportunities, setCourseOpportunities] = useState<any>([])
  const [userRole, setUserRole] = useState<any>(null)
  const {currentUser, logout} = useAuth()
  const user:any = currentUser
  const API_URL = process.env.REACT_APP_THEME_API_URL2
  const GET_COURSES_URL = `${API_URL}/course/participant-course-opportunities`

  useEffect(() => {
    const fetchCourseOpportunities = async () => {
      let content = await axios.get(`${GET_COURSES_URL}`)
      if (content.data.data) {
        setCourseOpportunities(content.data.data)
      }
      setIsDropDown(true)
    }
    if (pathname.includes('/kurser') || pathname.includes('/coach-kurser')) {
      fetchCourseOpportunities()
    } else {
      setIsDropDown(false)
    }
    if (typeof currentUser !== "undefined" && typeof currentUser.user_roles !== "undefined") {
      setUserRole(currentUser.user_roles)
    }
  }, [pathname])
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Start'
        fontIcon='fa-house'
      />
      {/*<AsideMenuItem
        to='/forum-feed'
        //icon='/media/icons/duotune/general/gen019.svg'
        title='Forum'
        fontIcon='fa-users'
  />*/}
      {/*<AsideMenuItem
        to='/builder'
        //icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />*/}
      {userRole !== null && userRole.id === 3 && (
        <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-4 ls-1'>UTBILDNINGAR</span>
          </div>
        </div>
        {isDropDown === true ? (
        <AsideMenuItemWithSub
          to='/kurser'
          title='Mina Kurser'
          fontIcon='fa-graduation-cap'
        >
          {courseOpportunities.filter((courseOpportunity: any) => !courseOpportunity.archived).map((courseOpportunity: any, index: any) => {
            return (
              <AsideMenuItem
                key={`course-opportunities-menu-item-${index}`}
                to={`/kurser/${courseOpportunity.id}/overview`}
                title={`${courseOpportunity.course.name}`}
                fontIcon='fa-graduation-cap'
              /> 
            );
          })}
        </AsideMenuItemWithSub>
        ): (
          <AsideMenuItem
            to='/kurser'
            title='Mina Kurser'
            fontIcon='fa-graduation-cap'
          />     
        )}
      {/*<AsideMenuItem
        to='/resultat'
        title='Resultat'
        fontIcon='fa-poll'
      />
      <AsideMenuItem
        to='/boka-coach'
        title='Boka Coach'
        fontIcon='fa-user-tie'
      />*/}
      </>
      )}
      {userRole !== null && userRole.id === 2 && (
      <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-4 ls-1'>ÖVERSIKT</span>
        </div>
      </div>
      {isDropDown === true ? (
        <AsideMenuItemWithSub
          to='/coach-kurser'
          title='Kurser'
          fontIcon='fa-graduation-cap'
        >
          {courseOpportunities.filter((courseOpportunity: any) => !courseOpportunity.archived).map((courseOpportunity: any, index: any) => {
            return (
              <AsideMenuItem
                key={`course-opportunities-menu-item-${index}`}
                to={`/coach-kurser/${courseOpportunity.id}/overview`}
                title={`${courseOpportunity.name}`}
                fontIcon='fa-graduation-cap'
              /> 
            );
          })}
        </AsideMenuItemWithSub>
        ): (
          <AsideMenuItem
            to='/coach-kurser'
            title='Kurser'
            fontIcon='fa-graduation-cap'
          />     
        )}
      {/*<AsideMenuItem
        to='/deltagare'
        title='Deltagare'
        fontIcon='fa-user-graduate'
      />
      <AsideMenuItem
        to='/resultat-coach'
        title='Resultat'
        fontIcon='fa-poll-h'
      />*/}
      </>
      )}
      {userRole !== null && userRole.id === 1 && (
      <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-uppercase fs-4 ls-1'>VERKTYG</span>
        </div>
      </div>
      <AsideMenuItem
        to='/all-users'
        title='Användare'
        fontIcon='fa-user'
      />
      <AsideMenuItem
        to='/courses'
        title='Kurser'
        fontIcon='fa-user-graduate'
      />
      <AsideMenuItem
        to='/all-courses'
        title='Kurstillfällen'
        fontIcon='fa-user-graduate'
      />
      {/*currentUser && typeof(user.user_roles) !== undefined && typeof(user.user_roles.name) !== undefined && (
        <div className='menu-user-bottom-content'>
        <div className='menu-item menu-user-symbol symbol symbol-circle symbol-35px overflow-hidden col-12'>
          <div
            className={
              `symbol-label fs-4 cursor-pointer symbol symbol-30px symbol-md-40px bg-light-${(typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Administratör' ? 'success' : (typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Coach' ? 'warning' : 'danger' ))} text-${(typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Administratör' ? 'success' : (typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Coach' ? 'warning' : 'danger' ))}`
            }
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            {currentUser.fullName?.charAt(0)}
          </div>
        </div>
        <div className='menu-item'>
          <i style={{color: '#fff'}} className={'menu-icon-signout fas fs-3 me-3 fa-sign-out'}></i>
        </div>
        </div>
      )*/}




      {/*<div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>ÖVRIGT</span>
        </div>
      </div>
      <AsideMenuItem
        to='/statistik'
        title='Statistik'
        fontIcon='fa-chart-line'
      />*/}
      </>
      )}
      <div className='menu-user-bottom-content'>

      {/*<div className='menu-item menu-user-symbol symbol symbol-circle symbol-35px overflow-hidden col-12'>
        <div
          className={
            `symbol-label fs-4 cursor-pointer symbol symbol-30px symbol-md-40px bg-light-${(typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Administratör' ? 'success' : (typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Coach' ? 'warning' : 'danger' ))} text-${(typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Administratör' ? 'success' : (typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Coach' ? 'warning' : 'danger' ))}`
          }
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {currentUser.fullName?.charAt(0)}
        </div>
        </div>*/}

      <AsideMenuItem
        to='/profile'
        title='Mitt konto'
        fontIcon='fa-user-graduate'
      />

      <AsideMenuItem
        to=''
        title='Logga ut'
        fontIcon='fa-sign-out'
        isLogout={true}
      />

      </div>
      {/*<div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        //icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
    </div>*/}
    </>
  )
}
