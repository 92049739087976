/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import { findTsaInvite, sendForm } from '../core/_requests'
import {useLocation} from 'react-router-dom'
import { Slider } from '@mui/material'
import { styled } from '@mui/material/styles'
import Rating, { IconContainerProps } from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import WestIcon from '@mui/icons-material/West'
import EastIcon from '@mui/icons-material/East'
import CheckIcon from '@mui/icons-material/Check'
import CircleIcon from '@mui/icons-material/Circle'
import StarIcon from '@mui/icons-material/Star'
import Chart from 'chart.js/auto'
import swal from 'sweetalert2'

const TsaFormPage: FC = () => {
  let location = useLocation()
  const [categories, setCategories] = useState<any>([])
  const [invite, setInvite] = useState<any>({})
  const [questionTextAreas, setQuestionTextAreas] = useState<any>([])
  const [textValues, setTextValues] = useState<any>({})
  const [isInvite, setIsInvite] = useState<boolean>(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [tsaInvite, setTsaInvite] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [value, setValue] = useState<number | null>(2)
  const [currentStep, setCurrentStep] = useState<any>(0)
  const [hover, setHover] = useState(-1)

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }))

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${customIcons[value]}`
  }

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement
      label: string
    }
  } = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: '1',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: '2',
    },
    3: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: '3',
    },
    4: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: '4',
    },
    5: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: '5',
    },
    6: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: '6',
    },
    7: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: '7',
    },
    8: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: '8',
    },
    9: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: '9',
    },
  }

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props
    return ( 
      <>
      <span {...other}>{customIcons[value].icon}</span>
      <span className='tsa-question-score-label'>{customIcons[value].label}</span>
      </>
    )
  } 

  const checkInvite = async () => {
    let inviteCode = location.pathname.replace('/tsa/', '')
    let data = await findTsaInvite(inviteCode)
    if (data.data && typeof data.data.invite !== undefined && data.data.categories !== undefined && typeof data.data.tsaQuestionsText !== undefined) {
      setTsaInvite(data.data.invite)
      for (let i in data.data.categories) {
        for (let j in data.data.categories[i].tsa_questions) {
          data.data.categories[i].tsa_questions[j]['answer'] = 5
        }
      }
      setInvite(data.data.invite)
      setCategories(data.data.categories)
      setQuestionTextAreas(data.data.tsaQuestionsText)
      setIsInvite(true)
    } else if (typeof data.data.invite !== 'undefined' && data.data.invite.completed === 1) {
      setIsCompleted(true)
    }
    setIsLoading(false) 
  }

  useEffect(() => {
    checkInvite()
  }, [])

    // proceed to the next step
    const nextStep = () => {
      const step = currentStep
      window.scrollTo({
        top: 0
      })
      setCurrentStep(step + 1)
    }

    // proceed to the previous step
    const prevStep = () => {
      const step = currentStep
      window.scrollTo({
        top: 0
      })
      setCurrentStep(step - 1)
    }

  const handleTextareaChange = (event: any, id: any) => {
    let getData : any = {...textValues}
    getData[`question-${id}`] = event.target.value
    setTextValues(getData)
  }

  const setChangedValue = async (categoryIndex: any, questionIndex: any, newValue: any) => {
    let categoriesToUpdate = [...categories]
    categoriesToUpdate[categoryIndex].tsa_questions[questionIndex]['answer'] = newValue
    setCategories(categoriesToUpdate)
  }

  const submit = async () => {
    const body = {
      invite: invite,
      categories: categories,
      question_text_values: textValues
    }
    const response = await sendForm(body)
    if (response.status === 200) {
      swal.fire({
        icon: 'success',
        title: 'Tack för att du har besvarat alla frågorna!',
        confirmButtonColor: '#07445e',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    }
  }

  if (isLoading) {
    return (
      <div className='container text-center'>
        laddar...
      </div>  
    )
  }

  if (isCompleted) {
    return (
      <div className='d-flex flex-row' style={{flex: '1 1', alignItems: 'center'}}>
        <div className='container text-center justify-content-center card p-5 w-800px'>
          <div>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/tsa-form-logo.png')} className='w-100px h-100px mt-5 mb-5' />
          </div>
          <div className={`mt-5 mb-5`}>
          <p className='fs-2'>Testet är slutfört! Tack för dina svar.</p>
          <a href='/dashboard'>
            <button className='btn btn-primary'>Gå tillbaka</button>
            </a>
        </div>
        </div>
      </div>
    )
  } 

  if (isInvite === false) {
    return (
      <div className='container'>
        Ej behörighet
      </div>  
    )
  }

  return (
    <div className='tsa-invite-form-page d-flex flex-row' style={{flex: '1 1', alignItems: 'center'}}>
    <div className='container text-center justify-content-center card p-5 w-800px'>
        <div>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/tsa-form-logo.png')} className='w-100px h-100px mt-5 mb-5' />
        </div>
        <div className={`${currentStep === 0 ? 'slide-left' : 'slide-left-more'} category mt-5 mb-5`}>
          <p className='fs-2'>Välkommen till Aktivt Ledarskap</p>
          <p className='fs-2'>{tsaInvite?.tsa?.user?.fullName ?? 'Anonym person'} har bjudit in dig att genomföra en 360 analys.<br/>Du svarar på frågor om {tsaInvite?.tsa?.user?.fullName ?? 'Anonym person'} med roll som {(tsaInvite?.tsa_role?.name.toLowerCase()) ?? 'okänd roll'}.</p>
        </div>
      {categories.map((category: any, categoryIndex: any) => {
        return (
          <div className={`${(categoryIndex + 1) !== currentStep ? 'slide-left-more' : 'slide-left'} category mt-5 mb-5`} key={`category-${categoryIndex}`}>
            <h2 className='mb-5'>{category.name}</h2>
            {category.tsa_questions && category.tsa_questions.map((question: any, questionIndex: any) => {
              return (
                <div className='mb-5' key={`question-${question?.id ?? questionIndex}`}>
                    <div className='d-flex justify-content-center'><div className=''><p>{questionIndex + 1}. {question.question}</p></div></div>
                    <StyledRating
                      name="highlight-selected-only"
                      defaultValue={5}
                      IconContainerComponent={IconContainer}
                      getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setChangedValue(categoryIndex, questionIndex, newValue)
                          setValue(newValue)
                        }
                      }}
                      //getLabelText={(value: number) => customIcons[value].label}
                      highlightSelectedOnly
                      max={9}
                      value={question.answer}
                    />
                </div>
              )
            })}
          </div>
        )
      })}
      <div className={`${currentStep === 9 ? 'slide-left' : 'slide-left-more'} category mt-5 mb-5`}>
        <h2>Övrigt</h2>
        {questionTextAreas && questionTextAreas.map((textQuestion: any, textQuestionIndex: any) => {
          return (
            <>
            <div key={`text-question-${textQuestionIndex}`}>
              <p className='tsa-question-label'>{textQuestion.question}</p>
              <textarea 
                rows={4}
                className='tsa-question-textarea'
                name="textValue"
                onChange={(e: any) => handleTextareaChange(e, textQuestion.id)}
              />
            </div>
            </>
          )
        })}
      </div>

      <div className='d-flex justify-content-center mt-10'>
        {isLoading === false && isInvite && (
          <>
          {currentStep > 0 && (
            <div className='back-btn col-4 p-0'>
            <button className='btn btn-primary' onClick={ prevStep }><WestIcon /> Förgående</button>
            </div>
          )}

          {currentStep === 0 && (
            <div className={`next-btn col-4 p-0`}>
            <button className={`btn ${currentStep === 0 ? 'recommendation-next-btn' : ''} btn-primary mb-10`} onClick={ nextStep }>Starta test</button>
            </div>
          )}

          {currentStep > 0 && currentStep < (categories.length + 1) && (
            <div className={`next-btn col-4 p-0`}>
            <button className={`btn ${currentStep === 1 ? 'recommendation-next-btn' : ''} btn-primary mb-10`} onClick={ nextStep }>Nästa <EastIcon /></button>
            </div>
          )}

          {currentStep >= (categories.length + 1) && (
            <div className='send-btn col-4 p-0'>
            <button className='btn btn-primary mb-10' onClick={submit}><CheckIcon /> Slutför test</button>
          </div>
          )}
          </>
        )}
        {/*<button className='btn btn-primary' onClick={submit}>Skicka</button>*/}
      </div>
    </div>
    </div>
  )
}

export {TsaFormPage}
