/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import SVG from 'react-inlinesvg'
import { findEvaluationInvite, sendForm } from '../core/_requests'
import {useLocation} from 'react-router-dom'
import { Slider } from '@mui/material'
import { styled } from '@mui/material/styles'
import Rating, { IconContainerProps } from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import Chart from 'chart.js/auto'
import swal from 'sweetalert2'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const EvaluationFormPage: FC = () => {

  let location = useLocation()
  const [questions, setQuestions] = useState<any>({
    question1: 5,
    question2: '',
    question3: '',
    question4: '',
    question5: '',
    question6: '',
    question7: '',
    question8: '',
  })
  const [invite, setInvite] = useState<any>({})
  const [questionTextAreas, setQuestionTextAreas] = useState<any>([])
  const [textValues, setTextValues] = useState<any>({})
  const [course, setCourse] = useState<any>({})
  const [currentStep, setCurrentStep] = useState<any>(0)
  const [isInvite, setIsInvite] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [value, setValue] = useState<number | null>(5);
  const [hover, setHover] = useState(-1)

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }))

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${customIcons[value]}`
  }

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    0: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: '0',
    },
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: '1',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: '2',
    },
    3: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: '3',
    },
    4: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: '4',
    },
    5: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: '5',
    },
    6: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: '6',
    },
    7: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: '7',
    },
    8: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: '8',
    },
    9: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: '9',
    },
    10: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: '10',
    }
  };

  const checkInvite = async () => {
    let inviteCode = location.pathname.replace('/evaluation/', '')
    let data = await findEvaluationInvite(inviteCode)
    if (data.data && typeof data.data.status !== undefined && typeof data.data.course !== undefined && data.data.status == 200) {
      setInvite(data.data.invite)
      setCourse(data.data.course)
      setIsInvite(true)
      setIsLoading(false)
    } else {
      //setIsInvite(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkInvite()
  }, [])

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return ( 
      <>
      <span {...other}>{customIcons[value].icon}</span>
      <span className='tsa-question-score-label'>{customIcons[value].label}</span>
      </>
    );
  } 

  const setChangedValue = async (questionIndex: any, newValue: any) => {
    let questionsToUpdate = {...questions}
    questionsToUpdate[questionIndex] = newValue
    setQuestions(questionsToUpdate)
  }

  // proceed to the next step
  const nextStep = () => {
    const step = currentStep;
    setCurrentStep(step + 1);
  }

  // proceed to the next step
  const prevStep = () => {
    const step = currentStep;
    setCurrentStep(step - 1);
  }

  const submit = async () => {
    const body = {
      questions,
      invite
      
    }
    const response = await sendForm(body)
    if (response.status === 200) {
      swal.fire({
        icon: 'success',
        title: 'Tack för att du har deltagit utvärderingen!',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {
          window.close()
        }
      })
    }
  }

  if (isLoading) {
    return (
      <div className='container'>
        laddar...
      </div>  
    )
  }

  if (isInvite === false) {
    return (
      <div className='container'>
        Ej behörighet
      </div>  
    )
  }

  return (
    <div className='h-100' style={{
      backgroundImage: `url("/media/login/login-image.png")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '70%',
      backgroundPosition: 'bottom',
    }}>
      <div className='container d-flex evaluation-container'>
        <div className='row w-100 justify-content-center'>
          {currentStep === 0 && (
            <div className='col-12 change-offset text-center mt-10 mb-10'>
              <h1 className='col-12 mt-10 mb-10'>Utvärdering</h1>
              <p className='col-12'>Vad tyckte du om din utbildning?</p>
            </div>
          )}
          {currentStep === 1 && (
            <div className='col-12 recommendation change-offset text-center mt-10 mb-10'>
            <div className='col-12 mb-5'>
              <p className='input-p'>Kommer du rekommendera denna kursen till andra?</p>
              <StyledRating
                name="highlight-selected-only"
                defaultValue={5}
                IconContainerComponent={IconContainer}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setChangedValue('question1', newValue)
                  setValue(newValue)
                }}
                //getLabelText={(value: number) => customIcons[value].label}
                highlightSelectedOnly
                max={10}
                value={questions.question1}
              />
              <div className='evaluation-troligt col-12 row'>
              <p className='col-3 offset-3 evaluation-inte-troligt-p'>Inte troligt</p>
              <p className='col-3 evaluation-troligt-p'>Mycket troligt</p>
              </div>
            </div>
          </div>
          )}
          {currentStep === 2 && (
            <div className='col-6 col-xs-12 mb-10 mt-20'>
              <p className='input-p'>Varför valde du {value}?</p>
              <input
                className='form-control mb-3'
                placeholder='Skriv här...'
                type={'text'}
                data-kt-element='input'
                value={questions.question2}
                onChange={(e) => setChangedValue('question2', e.target.value)}
              />
            </div>
          )}
          
          {currentStep === 3 && (
            <div className='col-6 col-xs-12 mb-10 mt-20'>
              <p className='input-p'>Vem skulle du rekommendera att gå denna utbildningen?</p>
              <input
                className='form-control mb-3'
                placeholder='Skriv här...'
                type={'text'}
                data-kt-element='input'
                value={questions.question3}
                onChange={(e) => setChangedValue('question3', e.target.value)}
              />
            </div>
          )}

          {currentStep === 4 && (
          <div className='col-6 col-xs-12 mb-10 mt-20'>
            <p className='input-p'>Hur kom du i kontakt med oss?</p>
            <input
              className='form-control mb-3'
              placeholder='Skriv här...'
              type={'text'}
              data-kt-element='input'
              value={questions.question4}
              onChange={(e) => setChangedValue('question4', e.target.value)}
            />
          </div>
          )}

          {currentStep === 5 && (
          <div className='col-6 col-xs-12 mb-10 mt-20'>
            <p className='input-p'>Vad känner du att du saknade i kursen?</p>
            <input
              className='form-control mb-3'
              placeholder='Skriv här...'
              type={'text'}
              data-kt-element='input'
              value={questions.question5}
              onChange={(e) => setChangedValue('question5', e.target.value)}
            />
          </div>
          )}

          {currentStep === 6 && (
          <div className='col-6 col-xs-12 mb-10 mt-20'>
            <p className='input-p'>Vad tyckte du var bäst med kursen?</p>
            <input
              className='form-control mb-3'
              placeholder='Skriv här...'
              type={'text'}
              data-kt-element='input'
              value={questions.question6}
              onChange={(e) => setChangedValue('question6', e.target.value)}
            />
          </div>
          )}

          {currentStep === 7 && (
          <div className='col-6 col-xs-12 mb-10 mt-20'>
            <p className='input-p'>Vad hade du velat fördjupa dig mer i från kursen?</p>
            <input
              className='form-control mb-3'
              placeholder='Skriv här...'
              type={'text'}
              data-kt-element='input'
              value={questions.question7}
              onChange={(e) => setChangedValue('question7', e.target.value)}
            />
          </div>
          )}

          {currentStep === 8 && (
          <div className='col-6 col-xs-12 mb-10 mt-20'>
            <p className='input-p'>Övriga kommentarer</p>
            <input
              className='form-control mb-3'
              placeholder='Skriv här...'
              type={'text'}
              data-kt-element='input'
              value={questions.question8}
              onChange={(e) => setChangedValue('question8', e.target.value)}
            />
          </div>
          )}
          <div className='col-12 row'>

          {currentStep > 0 && (
            <div className='back-btn col-4 offset-3 p-0'>
            <button className='btn btn-primary mb-10' onClick={ prevStep }>Tillbaka</button>
            </div>
          )}

          {currentStep === 0 && (
            <div className='start-btn text-center p-0'>
            <button className='btn btn-primary mb-10' onClick={ nextStep }>Start</button>
            </div>
          )}

          {currentStep !== 0 && currentStep < 8 && (
            <div className='next-btn col-2 text-end p-0'>
            <button className={`btn ${currentStep === 1 ? 'recommendation-next-btn' : ''} btn-primary mb-10`} onClick={ nextStep }>Nästa</button>
            </div>
          )}

          {currentStep === 8 && (
            <div className='send-btn col-2 text-end p-0'>
            <button className='btn btn-primary mb-10' onClick={submit}>Skicka</button>
          </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}

export {EvaluationFormPage}