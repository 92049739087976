import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import swal from 'sweetalert2'
import '../../../assets/css/custom-popup.module.css'



type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isLogout?: boolean
  isProfile?: boolean
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isLogout = false,
  isProfile = false
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {logout} = useAuth()
  const {aside} = config

  const submit = async () => {
    
      swal.fire({
        icon: 'warning',
        title: 'Är du säker på att du vill logga ut?',
        confirmButtonColor: '#07445e',
        confirmButtonText: 'Logga ut',
        showDenyButton: true,
        denyButtonColor: "#d32f2f",
        denyButtonText: "Tillbaka",
        didOpen: () => {
          const wpopup = swal.getContainer();
        }
      }).then((result) => {
        if (result.isConfirmed) {
          logout()
        }
      })
    
  }

  
  return (
    <div className='menu-item'>
      {isLogout ? (
        <a className={clsx('menu-link without-sub', {active: isActive})} onClick={submit}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && aside.menuIcon === 'svg' && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && aside.menuIcon === 'font' && <i className={clsx('fas fs-3 me-3', fontIcon)}></i>}
          <span className='menu-title mr-1'>{title}</span>
        </a>
      ) : (
        <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('fas fs-3 me-3', fontIcon)}></i>}
        <span className='menu-title mr-1 mt-1 fs-6'>{title}</span>
      </Link>
      )}
      {children}
    </div>
  )
}

export {AsideMenuItem}
