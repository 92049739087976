import React, {useState, CSSProperties} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import swal from 'sweetalert2'
import MoonLoader from "react-spinners/MoonLoader"
import {AxiosResponse, AxiosError} from 'axios'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Fel e-post format')
    .min(3, 'Minst 3 tecken')
    .max(80, 'Max 80 tecken')
    .required('E-post är obligatorisk'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const [color, setColor] = useState("#08506e")

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    //borderColor: "red",
  }
  
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email.toLowerCase())
          .then((data: AxiosResponse) => {
            swal.fire({
              icon: 'success',
              title: 'Ett mail ska ha skickats till dig. Kolla i din inkorg.',
              confirmButtonColor: '#07445e',
              confirmButtonText: 'Gå till startsidan',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = '/auth/login'
              }
            })
            setLoading(false)
          })
          .catch((data: AxiosError) => {
            if (data.response?.status === 500) {
              setLoading(false)
              setSubmitting(false)
              swal.fire({
                icon: 'error',
                title: 'E-posten är inte registrerad i våra system. Vänligen kontakta vårt företag.',
                confirmButtonColor: '#07445e',
                confirmButtonText: 'Ok',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              })
            } else if (data.response?.status === 401) {
              setLoading(false)
              setSubmitting(false)
              swal.fire({
                icon: 'error',
                title: 'Ditt konto är inte aktiverat. Vänligen använd registreringslänken som skickades till din e-post.',
                confirmButtonColor: '#07445e',
                confirmButtonText: 'Ok',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              })
            }
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Glömt lösenord ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Fyll i din e-post så kommer du få ett nytt lösenord.</div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <MoonLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={75}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
          {loading === false && (
            <>
              <label className='form-label fw-bolder text-gray-900 fs-6'>E-post</label>
              <input
                type='email'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
            </>
            )}
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        {loading === false && (
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              <span className='indicator-label'>Skicka</span>
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Avbryt
              </button>
            </Link>{' '}
          </div>
        )}
        {/* end::Form group */}
      </form>
    </>
  )
}
