import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const FIND_TSA_INVITE = `${API_URL}/tsa/find-tsa-invite`
export const POST_TSA_FORM = `${API_URL}/tsa/send-form`

const findTsaInvite = async (param: string) => {
  const data = await axios.get(`${FIND_TSA_INVITE}/${param}`)
  return data
}

const sendForm = async (body: any) => {
  const data = await axios.post(`${POST_TSA_FORM}`, body)
  return data
}

export {findTsaInvite, sendForm}