/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import swal from 'sweetalert2'
import '../../../assets/css/custom-popup.module.css'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()

  const submit = async () => {
    
    swal.fire({
      icon: 'warning',
      title: 'Är du säker på att du vill logga ut?',
      confirmButtonColor: '#07445e',
      confirmButtonText: 'Logga ut',
      showDenyButton: true,
      denyButtonColor: "#d32f2f",
      denyButtonText: "Tillbaka",
    }).then((result) => {
      if (result.isConfirmed) {
        logout()
      }
    })
  
  }

  const user: any = currentUser
  let email,
      fullname
  if (user.fullName && user.fullName.length > 23) {
    fullname = user.fullName.substring(0,23)
    fullname += '...'
  } else {
    fullname = user?.fullName ?? ''
  }
  if (user.email && user.email.length > 23) {
    email = user.email.substring(0,23)
    email += '...'
  } else {
    email = user?.email ?? ''
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item'>
        <div className='menu-content d-flex align-items-center px-3'>
          {user && typeof(user.user_roles) !== undefined && typeof(user.user_roles.name) !== undefined && (
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-circle symbol-50px'>
              <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${(typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Administratör' ? 'success' : (typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Coach' ? 'warning' : 'danger' ))}`,
                `text-${(typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Administratör' ? 'success' : (typeof user.user_roles !== 'undefined' && user.user_roles.name == 'Coach' ? 'warning' : 'danger' ))}`
              )}

              >
              {user.fullName?.charAt(0)}
              </div>
            </div>
          </div>
          )}
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {fullname}
            </div>
            <p className='fw-bold text-muted text-hover-primary fs-7'>
              {email}
            </p>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/*<div className='menu-item px-5'>
        <Link to={'/profile/overview'} className='menu-link px-5'>
          Min profil
        </Link>
      </div>*/}

      <div className='menu-item px-5'>
        <a onClick={submit} className='menu-link px-5'>
          Logga ut
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
