import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const ParticipantCoursePage = lazy(() => import('../modules/course/ParticipantCoursePage'))
  const CoachCoursePage = lazy(() => import('../modules/coach-course/CoachCoursePage'))
  const AdminCoursePage = lazy(() => import('../modules/admin-course/AdminCoursePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const AllUsersPage = lazy(() => import('../pages/user-list/AllUsersPage'))
  const CourseListPage = lazy(() => import('../pages/course/CourseListPage'))
  const CourseMainListPage = lazy(() => import('../pages/courses-list/CourseMainListPage'))
  const ForumFeedPage = lazy(() => import('../pages/forum/ForumFeedPage'))
  const MyCoursesPage = lazy(() => import('../pages/my-courses/MyCoursesPage'))
  const MyResultsPage = lazy(() => import('../pages/my-results/MyResultsPage'))
  const HireCoachPage = lazy(() => import('../pages/hire-coach/HireCoachPage'))
  const CoachCoursesPage = lazy(() => import('../pages/coach-courses/CoachCoursesPage'))
  const CoachParticipantsPage = lazy(() => import('../pages/coach-participants/CoachParticipantsPage'))
  const StatisticsPage = lazy(() => import('../pages/statistics/StatisticsPage'))
  const ResultsCoachPage = lazy(() => import('../pages/results-coach/ResultsCoachPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='all-users'
          element={
            <SuspensedView>
              <AllUsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='courses'
          element={
            <SuspensedView>
              <CourseMainListPage />
            </SuspensedView>
          }
        />
        <Route
          path='all-courses'
          element={
            <SuspensedView>
              <CourseListPage />
            </SuspensedView>
          }
        />
        <Route
          path='all-courses/*'
          element={
            <SuspensedView>
              <AdminCoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='forum-feed'
          element={
            <SuspensedView>
              <ForumFeedPage />
            </SuspensedView>
          }
        />
        <Route
          path='kurser'
          element={
            <SuspensedView>
              <MyCoursesPage />
            </SuspensedView>
          }
        />
        <Route
          path='kurser/*'
          element={
            <SuspensedView>
              <ParticipantCoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='resultat'
          element={
            <SuspensedView>
              <MyResultsPage />
            </SuspensedView>
          }
        />
        <Route
          path='boka-coach'
          element={
            <SuspensedView>
              <HireCoachPage />
            </SuspensedView>
          }
        />
        <Route
          path='coach-kurser'
          element={
            <SuspensedView>
              <CoachCoursesPage />
            </SuspensedView>
          }
        />
        <Route
          path='coach-kurser/*'
          element={
            <SuspensedView>
              <CoachCoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='deltagare'
          element={
            <SuspensedView>
              <CoachParticipantsPage />
            </SuspensedView>
          }
        />
        <Route
          path='statistik'
          element={
            <SuspensedView>
              <StatisticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='resultat-coach'
          element={
            <SuspensedView>
              <ResultsCoachPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
